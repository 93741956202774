import React from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-awesome-reveal';
import { Global, css } from '@emotion/react';

import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Formularz</title>
        <html lang="pl" />
        <meta name="description" content="Formularz współpracy" />
      </Helmet>
      <Navigation location={location} />
      <Global
        styles={css`
          #___gatsby {
            height: 100%;
          }
          #gatsby-focus-wrapper {
            display: flex;
            flex-direction: column;
          }
          .form-content {
            flex: 1;
            padding: 0px 0 20px 0;
          }
          .form-iframe {
            height: 100%;
            width: 100%;
          }
          .footer {
            padding: 0;
          }
        `}
      />
      <div className="form-content">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSd2eg_3eIxcnXHvkJ70Ff4k84Zj8DGbgifbkc_GpfTzXe4InQ/viewform?embedded=true"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          className="form-iframe"
        >
          Ładuję…
        </iframe>
      </div>
    </>
  );
};
